var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-wait-time-rate-sidebar","visible":_vm.isActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.waitTimeRateData.id ? 'Update' : 'Add New')+" Wait Time Rate ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){_vm.resetForm();
          hide();}}})],1),_c('validation-observer',{ref:"newWaitTimeRateForm"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('b-form-group',{attrs:{"label":"Time in Min","label-for":"time"}},[_c('validation-provider',{attrs:{"name":"Time in Minutes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"time","state":errors.length > 0 ? false : null,"name":"time","type":"number","step":".01"},model:{value:(_vm.waitTimeRateData.timeInMin),callback:function ($$v) {_vm.$set(_vm.waitTimeRateData, "timeInMin", $$v)},expression:"waitTimeRateData.timeInMin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Fee","label-for":"fee"}},[_c('validation-provider',{attrs:{"name":"Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fee","state":errors.length > 0 ? false : null,"name":"fee","type":"number","step":".01"},model:{value:(_vm.waitTimeRateData.fee),callback:function ($$v) {_vm.$set(_vm.waitTimeRateData, "fee", $$v)},expression:"waitTimeRateData.fee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.waitTimeRateData.id ? 'Update' : 'Add')+" ")],1),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){_vm.resetForm();
              hide();}}},[_vm._v(" Cancel ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }