import { ref } from '@vue/composition-api';
import useWaitTimeRate from './useWaitTimeRate';

const useWaitTimeList = () => {
  const { getWaitTimeRates } = useWaitTimeRate();

  const refWaitTimeRateTable = ref(null);
  const tableColumns = [
    { key: 'timeInMin' },
    { key: 'fee' },
    { key: 'actions' },
  ];

  const getWaitTimeRateList = (_, callback) => {
    getWaitTimeRates().then((rates) => {
      callback(rates);
    });
  };

  return {
    // Reactive
    refWaitTimeRateTable,
    tableColumns,

    // Method
    getWaitTimeRateList,
  };
};

export default useWaitTimeList;
