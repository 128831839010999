import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useWaitTimeRate = () => {
  const toast = useToast();

  const createWaitTimeRate = async (waitTimeRate) => {
    try {
      await store.dispatch('waitTimeRate/create', waitTimeRate);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the wait time rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const deleteWaitTimeRate = async (waitTimeRate) => {
    try {
      await store.dispatch('waitTimeRate/remove', waitTimeRate);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error deleting the wait time rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getWaitTimeRates = async () => {
    try {
      return await store.dispatch('waitTimeRate/getList');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the list of wait time rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const updateWaitTimeRate = async (waitTimeRate) => {
    try {
      await store.dispatch('waitTimeRate/update', waitTimeRate);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the wait time rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    createWaitTimeRate,
    deleteWaitTimeRate,
    getWaitTimeRates,
    updateWaitTimeRate,
  };
};

export default useWaitTimeRate;
