<template>
  <div>
    <wait-time-form
      v-if="isWaitTimeRateFormActive"
      :is-active.sync="isWaitTimeRateFormActive"
      :waitTimeRate="waitTimeRate"
      @form-submitted="formSubmitted"
    />

    <b-card no-body>
      <div class="m-2">
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            @click="openForm"
          >
            <span class="text-nowrap">Add Wait Time Rate</span>
          </b-button>
        </div>
      </div>

      <b-table
        ref="refWaitTimeRateTable"
        class="position-relative"
        :items="getWaitTimeRateList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No records found"
      >
        <template #cell(timeInMin)="data">
          <p class="font-weight-bold d-block text-nowrap">
            {{ data.item.timeInMin }}
          </p>
        </template>

        <template #cell(fee)="data">
          <p class=" d-block text-nowrap">
            £{{ data.item.fee }}
          </p>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editRate(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard, BCol, BDropdown, BDropdownItem, BRow, BTable } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import Swal from 'sweetalert2';
import useWaitTimeList from "@/modules/rate/composables/useWaitTimeList";
import useWaitTimeRate from "@/modules/rate/composables/useWaitTimeRate";
import WaitTimeForm from '@/modules/rate/components/WaitTimeForm.vue';

export default {
  setup() {
    const { deleteWaitTimeRate } = useWaitTimeRate();
    const { refWaitTimeRateTable, tableColumns, getWaitTimeRateList } = useWaitTimeList();

    const waitTimeRateEmpty = {
      fee: null,
      id: null,
      timeInMin: null,
    };

    const isWaitTimeRateFormActive = ref(false);
    const waitTimeRate = ref({ ...waitTimeRateEmpty });

    const editRate = (rate) => {
      waitTimeRate.value = { ...rate };
      isWaitTimeRateFormActive.value = true;
    };

    const formSubmitted = () => {
      isWaitTimeRateFormActive.value = false;
      waitTimeRate.value = { ...waitTimeRateEmpty };
      refWaitTimeRateTable.value.refresh();
    };

    const openForm = () => {
      waitTimeRate.value = { ...waitTimeRateEmpty };
      isWaitTimeRateFormActive.value = true;
    };

    const removeRate = async (rate) => {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await deleteWaitTimeRate(rate);

      refWaitTimeRateTable.value.refresh();
    };

    return {
      // Reactive
      waitTimeRate,
      isWaitTimeRateFormActive,
      tableColumns,
      refWaitTimeRateTable,

      // Method
      editRate,
      formSubmitted,
      getWaitTimeRateList,
      openForm,
      removeRate
    };
  },
  components: {
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BRow,
    BTable,
    WaitTimeForm,
  }
};
</script>