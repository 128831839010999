<template>
  <b-sidebar
    id="add-new-wait-time-rate-sidebar"
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ waitTimeRateData.id ? 'Update' : 'Add New' }} Wait Time Rate
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="
            resetForm();
            hide();
          "
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="newWaitTimeRateForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validateForm()"
        >
          <b-form-group
            label="Time in Min"
            label-for="time"
          >
            <validation-provider
              #default="{ errors }"
              name="Time in Minutes"
              rules="required"
            >
              <b-form-input
                id="time"
                v-model="waitTimeRateData.timeInMin"
                :state="errors.length > 0 ? false : null"
                name="time"
                type="number"
                step=".01"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Fee"
            label-for="fee"
          >
            <validation-provider
              #default="{ errors }"
              name="Fee"
              rules="required"
            >
              <b-form-input
                id="fee"
                v-model="waitTimeRateData.fee"
                :state="errors.length > 0 ? false : null"
                name="fee"
                type="number"
                step=".01"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
              />
              {{ waitTimeRateData.id ? 'Update' : 'Add' }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="
                resetForm();
                hide();
              "
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BSidebar,
  BSpinner,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import { required } from '@validations';
import { useToast } from 'vue-toastification/composition';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import useWaitTimeRate from '@/modules/rate/composables/useWaitTimeRate';
import vSelect from 'vue-select';

export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    waitTimeRate: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const toast = useToast();
    const { createWaitTimeRate, updateWaitTimeRate } = useWaitTimeRate();

    const waitTimeRateData = ref(props.waitTimeRate);
    const newWaitTimeRateForm = ref(null);
    const isLoading = ref(false);

    const resetForm = () => {
      newWaitTimeRateForm.value.reset();
    };

    const validateForm = async () => {
      if (isLoading.value) return;

      isLoading.value = true;

      const valid = await newWaitTimeRateForm.value.validate();
      if (!valid) {
        isLoading.value = false;
        return;
      }

      try {
        waitTimeRateData.value.id
          ? await updateWaitTimeRate(waitTimeRateData.value)
          : await createWaitTimeRate(waitTimeRateData.value);

        ctx.emit('form-submitted');
      } catch (error) {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'An error occurred.',
            icon: 'XCircleIcon',
          },
        });
      }

      isLoading.value = false;
    };

    return {
      // Reacitve
      waitTimeRateData,
      newWaitTimeRateForm,
      isLoading,

      // Method
      resetForm,
      validateForm,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BSpinner,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-wait-time-rate-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
